<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('MENU.connected_printers') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <div class="row">
            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('connected_printers.name') }}<span class="text-danger">*</span></label>
                    <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''" :placeholder="$t('price_lists.name')"/>
                    <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('connected_printers.type') }}<span class="text-danger">*</span></label>
                    <select type="text" v-model="data.type" class="custom-select" :class="validation && validation.type ? 'is-invalid' : ''">
                      <option v-for="(row, index) in type_list" :value="row.id" :key="'type'+index">{{row.title}}</option>
                    </select>
                    <span v-if="validation && validation.type" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.type[0] }}
                            </span>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('connected_printers.notes') }}</label>
                    <textarea class="form-control" v-model="data.notes"></textarea>
                    <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.notes[0] }}
                            </span>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label>&ensp;</label>
                    <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
                  </div>

                </div>
              </div>
            </div>
          </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from",
  data() {
    return {
      mainRoute: 'settings/connected_printers',
      mainRouteDependency: 'base/dependency',
      items: [],

      idEdit: this.$route.params.id ? this.$route.params.id : null,
      data: {
        name: null,
        type: null,
        is_active: true,
        notes: null,
      },
      isEditing: false,
      validation: null,

      type_list: [],
    };
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {

      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/settings/connected-printers');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/settings/connected-printers');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.type = response.data.data.type;
        this.data.is_active = response.data.data.is_active;
        this.data.notes = response.data.data.notes;
      });
    },

    async getType() {
      await ApiService.get(this.mainRouteDependency+"/connected_printer_type").then((response) => {
        this.type_list = response.data.data
      });
    },



  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.connected_printers"), route: '/settings/connected-printers'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    let promise = this.getType();
    if (this.idEdit) {
      Promise.all([promise]).then(()=>{
        this.getData();
      })

    }

  },
};
</script>


